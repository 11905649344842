<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">
    <div>
      <Breadcrumbs />

      <v-menu
          v-model="showColumnsMenu"
          :close-on-content-click="false"
          :nudge-width="450"
          offset-x
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-5 inlineblock" v-bind="attrs" v-on="on" icon
            ><v-icon color="grey">mdi-cog-outline</v-icon></v-btn
          >
        </template>
        <v-card style="overflow: hidden">
          <v-chip
            style="width: 225px; margin: 15px"
            :color="allColumnsDisplayed ? 'primary' : 'grey'"
            @click="toggleAllColumns"
            text-color="white"
          >
            <v-avatar v-if="allColumnsDisplayed" left>
              <v-icon>mdi-check</v-icon>
            </v-avatar>
            {{ $t("filters.allfilters") }}
          </v-chip>
          <v-row no-gutters style="margin-right: 50px">
            <v-col cols="6">
              <v-list>
                <v-list-item v-for="(column, index) in columns.slice(0, columns.length / 2)" :key="index">
                  <template>
                    <v-chip
                      style="width: 225px"
                      :color="column.active ? 'primary' : 'grey'"
                      @click="column.active = !column.active"
                      text-color="white"
                    >
                      <v-avatar v-if="column.active" left>
                        <v-icon>mdi-check</v-icon>
                      </v-avatar>
                      {{ column.title }}
                    </v-chip>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col no-gutters cols="6">
              <v-list>
                <v-list-item v-for="(column, index) in columns.slice(columns.length / 2, columns.length)" :key="index">
                  <template>
                    <v-chip
                      style="width: 225px"
                      :color="column.active ? 'primary' : 'grey'"
                      @click="column.active = !column.active"
                      text-color="white"
                    >
                      <v-avatar v-if="column.active" left>
                        <v-icon>mdi-check</v-icon>
                      </v-avatar>
                      {{ column.title }}
                    </v-chip>
                  </template>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-btn icon absolute top right @click="showColumnsMenu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card>
      </v-menu>

      <v-text-field
        class="ml-3 mt-7"
        v-model="generalSearch"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        hide-details
        dense
        filled
        clearable
        :loading="isSearching"
        @keydown="handleSearch"
        @click:clear="handleSearch"
        style="display:inline-block;min-width:400px;"
      ></v-text-field>

      <div class="top-right-fab">
        <v-btn
          :disabled="!canManageCustomers"
          style="background-color: #009f4d;"
          fab
          @click="create"
        >
          <v-icon style="color: #fff">mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <v-container :loading="!customers" fluid>

      <div v-if="!customers">
        <v-row>
          <v-col cols="12">
            <v-card class="border10">
              <v-skeleton-loader
                type="table-heading, divider, table-heading, divider, table-heading, divider"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-show="customers">
        <v-row>
          <v-col cols="12">
            <v-card class="border10">
              <div ref="table"></div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator_bootstrap3.min.css";

import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import { getLanguage, getLanguageWithoutRegion, parseError, getTabulatorLangsObject, objectMatchSearch, displayPaginationInfo } from "@/utils/utils"
import UserService from "@/services/user.service";
import Breadcrumbs from "@/components/Breadcrumbs";

var table = null;

export default {
  components: {
    Breadcrumbs,
  },
  computed: {
    ...mapGetters(["canManageCustomers"]),
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    allColumnsDisplayed() {
      for (let column of this.columns) {
        if (!column.active) {
          return false;
        }
      }
      return true;
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      customers: null,
      searchInput: "",
      showColumnsMenu: false,
      selected: [],
      tabulatorColumns: [],
      columns: [
        {
          field: "formattedName",
          title: this.$t("users.name"),
          active: true,
        },
        {
          field: "formattedCompany",
          title: this.$t("customers.company"),
          active: true,
        },
        {
          field: "formattedDealer",
          title: this.$t("users.dealer"),
          active: true,
        },
        {
          field: "formattedAddress",
          title: this.$t("users.address"),
          active: true,
        },
        {
          field: "formattedEmail",
          title: this.$t("users.email"),
          active: true,
        },
        {
          field: "formattedPhone",
          title: this.$t("users.phone"),
          active: false,
        },
      ],
      generalSearch: '',
      isSearching: false
    };
  },
  mounted() {
    console.log("Customers mounted");
    this.$store.dispatch("setBreadcrumbs", [
      { text: this.$t("customers.customers"), href: "/customers" },
    ]);

    let displayedColumnKeys = localStorage.getItem('displayedCustomersColumnKeys');
    if (displayedColumnKeys) {
      displayedColumnKeys = displayedColumnKeys.split(',');
      for (let column of this.columns) {
        column.active = displayedColumnKeys.find(k => column.field == k) != undefined;
      }
    }
    this.updateTabulatorColumns();
    this.initTabulator();

    this.getCustomers();
  },
  created: function () {
    console.log("Customers created");
  },
  watch: {
    showColumnsMenu() {
      if (!this.showColumnsMenu) {
        this.updateTabulatorColumns();
        table.setColumns(this.tabulatorColumns);

        this.refreshCustomers();
      }
    },
  },
  methods: {
    ...mapMutations(['openToast', 'showErrorMessage']),
    ...mapActions(['logout']),
    async create() {
      let data = { locale: this.locale, userInfo: {} };

      let response = await UserService.createCustomer(data);
      if (response.status == 200) {
        this.$router.push("/customer/" + response.data.id);
      } else {
        this.openToast({ message: response, duration: 15000, color: "red" });
      }
    },
    updateTabulatorColumns() {
      this.tabulatorColumns = this.columns.filter(c => c.active);

      let displayedColumnKeys = this.tabulatorColumns.map(c => c.field);
      localStorage.setItem('displayedCustomersColumnKeys', displayedColumnKeys);
    },
    toggleAllColumns() {
      let allColumnsDisplayed = !this.allColumnsDisplayed;

      this.columns.forEach((column) => {
        column.active = allColumnsDisplayed;
      });
    },
    initTabulator() {

      var scope = this;

      let columnsLangData = {
        "formattedName": this.$t("users.name"),
        "formattedCompany": this.$t("customers.company"),
        "formattedDealer": this.$t("users.dealer"),
        "formattedAddress": this.$t("users.address"),
        "formattedEmail": this.$t("users.email"),
        "formattedPhone": this.$t("users.phone")
      };

      table = new Tabulator(this.$refs.table, {
        layout: "fitColumns",
        columns: this.tabulatorColumns,
        persistence: { sort: true, filter: true, columns: false },
        persistenceID: "persistenceCustomersReport",
        pagination: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 25, 50, 100],
        paginationCounter: (pageSize, currentRow, currentPage, totalRows, totalPages) => displayPaginationInfo(pageSize, currentRow, currentPage, totalRows, totalPages, scope),
        locale: getLanguageWithoutRegion(getLanguage()),
        langs: getTabulatorLangsObject(this.$i18n, columnsLangData),
      });

      table.on("rowClick", function (e, row) {
        scope.$router.push("/customer/" + row.getData().id);
      });
    },
    selectCustomer: function (customer) {
      console.log(customer.id);
      this.$router.push("/customer/" + customer.id);
    },
    getCustomers() {
      console.log('Reloading customers...')

        var scope = this;
        UserService.getCustomers(getLanguage())
        .then(function (response) {

          console.log(response.data)
          scope.customers = response.data;

          table.setData(scope.customers);
        })
        .catch(function (error) {
          // handle error
          let errorMsg = parseError(error);
          console.log(errorMsg);
          scope.showErrorMessage(errorMsg);
          if (error.toString().includes('401')) {
            scope.logout(scope.$socket);
          }
          else if (error.toString().includes('500') && 
            scope.$router.currentRoute.path.includes('/customers')) {
            console.log('Redirecting to home from customers...')
            scope.$router.push('/');
          }
        })
     },
     handleSearch() {
      let scope = this;
      this.isSearching = true;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.clearSearch();
      }, 1000);
    },
    clearSearch() {
      this.isSearching = false;
      
      this.refreshCustomers();
    },
    refreshCustomers() {
      let customers = this.customers;
      if (this.generalSearch) {
        customers = customers.filter(u => objectMatchSearch(u, this.generalSearch, this.tabulatorColumns.map(c => c.field)));
      }
      table.setData(customers);
    }
  },
};
</script>

<style>
.badge-right-top {
  position: absolute!important;
  right: 20px;
  top: 20px;
}
.inlineblock {
  display: inline-block;
}
.v-badge--tile .v-badge__badge {
  border-radius: 3px;
}
.v-breadcrumbs {
  display: inline-block;
}
</style>